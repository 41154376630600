<template>
  <div>
    <h4>Alimentation</h4>
    
    <data-table
      :data-source="alimentations"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-aliment"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      title="Ajout d'espèce"
      id="add-aliment"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="addingEspece"
      >
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            type="text"
            id="add-libelle"
            name="add-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>
    </modal>
    <modal
      title="Modification de l'espèce"
      id="update-aliment"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editingEspece"
      >
        <div class="form-group">
          <label for="edit-libelle">Libelle</label>
          <input
            type="text"
            id="edit-libelle"
            name="edit-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {ADD_ALIMENTATION,  EDIT_ALIMENTATION } from '../../../graphql/identification'
import DataTable from '../../../components/dataTable/local.vue'
import {TEXT_TYPE, COMPONENT_TYPE } from '../../../components/dataTable/dataType'
import Modal from '../../../components/modal.vue'
const Actions = () => import('../../../components/admin/identification/alimentAction.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
        selectedEspece: {
            handler(){
                if(this.selectedEspece !== null) {
                    this.libelle = this.selectedEspece.libelle
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedEspece: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedEspece(null)
            this.libelle = null
        },
        addingEspece(){
            let data = {
                libelle: this.libelle,
            }
            this.$apollo.mutate({
                mutation: ADD_ALIMENTATION,
                variables: {
                    "alimentation": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Alimentation add successfully on uid ${d.addEspece}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editingEspece(){
            let data = {
                libelle: this.libelle,
            }
            this.$apollo.mutate({
                mutation: EDIT_ALIMENTATION,
                variables: {
                    "alimentation": {
                        ...data
                    }, 
                    "uid": this.selectedEspece.uid
                },
                update: () => {
                    console.log(`Alimentation  ${this.selectedEspece.libelle} edit successfully on uid ${this.selectedEspece.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            alimentations: 'identification/aliments',
            selectedEspece: 'selectedObject'
        }),
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        }
    }
}
</script>

<style>

</style>